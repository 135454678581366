@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Lato:wght@400;700&family=Merriweather:wght@400;700&display=swap");

/* Global styles */
html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  /* background: linear-gradient(to right, blue, green); */
  background: gray;
  background-repeat: no-repeat;
  background-attachment: fixed; /* Ensure the background stays fixed during scrolling */
  font-family: "Merriweather", serif;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: inherit; /* Inherit the gradient from the root */
}

.ContentWrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background: inherit; /* Inherit the gradient from the root */
}

/* Updated margin and padding to ensure no gaps */
.ContentWrapper > div {
  margin: 0; /* Remove unnecessary margins between sections */
}

section {
  margin: 0;
  padding: 0;
  background: inherit; /* Inherit background consistency */
}

/* Ensure consistent background color and remove extra margins */
#cta,
#hero,
#showcase,
#features {
  margin: 0;
  padding: 0;
  background: inherit; /* Ensure consistent background */
}

/* Styling for smooth scrolling effect */
.pb-8,
.sm\:pb-12,
.lg\:pb-16 {
  padding-bottom: 0; /* Ensure no additional padding at the bottom of sections */
}

/* Divider styling, though we will remove them */
.divider {
  height: 0;
  margin: 0;
  background: inherit; /* Inherit background consistency */
  border: none;
}
